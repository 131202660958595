import * as React from "react";

import { StaticImage } from "gatsby-plugin-image";
import TwoImages from "../../components/Layout/TwoImages";

const Marmorino = () => {
  return (
    <div
      style={{
        maxWidth: "800px",
        margin: "0 auto",
        padding: "30px",
      }}
    >
      <h1
        style={{ textAlign: "center", fontSize: "36px", marginBottom: "30px" }}
      >
        Marmorino
      </h1>

      <p style={{ fontSize: "18px", lineHeight: "1.5" }}>
        This is a type of Venetian plaster that is made from a mixture of lime
        putty and marble dust. It creates a smooth, glossy finish that is
        similar to polished marble.
      </p>
      <TwoImages colour="#282625">
        <StaticImage
          formats={["auto", "webp", "avif"]}
          alt="marmurino finish sample"
          src="../images/finishes-page/marmurino/marmurino_1_800x600.jpg"
          placeholder="blurred"
        />

        <StaticImage
          formats={["auto", "webp", "avif"]}
          alt="marmurino finish sample"
          src="../images/finishes-page/marmurino/marmurino_2_800x600.jpg"
          placeholder="blurred"
        />
      </TwoImages>
    </div>
  );
};

export default Marmorino;
