import * as React from "react";

import styled from "styled-components";

export const Container = styled.div`
  display: grid;
  grid-template-columns: auto minmax(200px, 1200px) auto;
`;

export const TwoImageRow = styled.section`
  display: grid;
  grid-template-columns: 1fr 1fr;
  color: #fff;
  @media (max-width: 980px) {
    text-align: center;
    grid-template-columns: 1fr;
  }
`;

export default function TwoImages({
  colour,
  children,
}: {
  colour?: string;
  children: React.ReactNode;
}) {
  return (
    <Container>
      <aside style={{ background: colour }}></aside>
      <TwoImageRow>{children}</TwoImageRow>
      <aside style={{ background: colour }}></aside>
    </Container>
  );
}
